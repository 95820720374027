import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import PropTypes from "prop-types";

import { extendRental } from "../../../App/Api/extendRental";
import DayPicker from "react-day-picker";
import $ from "jquery";
import StripeCheckout from "react-stripe-checkout";
import { extendMoment } from "moment-range";
import originalMoment from "moment";
const moment = extendMoment(originalMoment);

class ExtendRentalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalAmount: 0,
      rentalDeliveryAmount: 0,
      rentalGst: 0,
      totalRentalAmount: 0,
      selectedDay: null
    };
  }

  handleStartDayChange = selectedDay => {
    console.log("THIS", selectedDay);
    const vm = this;
    vm.setState(
      {
        selectedDay: selectedDay
      },
      () => {
        this.calculateTotal();
      }
    );
  };

  handleToken = token => {
    let obj = {
      endDate: moment.utc(this.state.selectedDay).format(),
      endTime: this.props.endTime,
      stripeToken: token.id,
      base64EncodedAppleReceipt: ""
    };
    extendRental(this.props.equipmentId, obj).then(res => {
      console.log(res);
      if (res.code === "EXTENSION_DECLINED") {
        console.log(res.code);
        alert(res.message);
      } else {
        this.props.toggle("extendRental");
        this.props.toggle("success");
      }
    });
  };

  calculateTotal = () => {
    const vm = this;

    let myData = {
      endDate: moment.utc(vm.state.selectedDay).format(),
      endTime: vm.props.endTime
    };
    console.log(myData);
    $.ajax({
      method: "POST",
      dataType: "json",
      traditional: true,
      data: JSON.stringify(myData),
      xhrFields: {
        withCredentials: true
      },

      url: `${process.env.REACT_APP_API}/rentals/${this
        .props.equipmentId}/calculate`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(function(res) {
        console.log("********* POST TEST **********");
        console.log(res.data);
        vm.setState({
          totalRentalAmount: res.data.total.toFixed(2),
          rentalAmount: res.data.rentalAmount.toFixed(2),
          // rentalGst: res.data.gst.toFixed(2),
          rentalGst: (res.data.gst + res.data.platformFee).toFixed(2),
          rentalDeliveryAmount: res.data.deliveryAmount.toFixed(2),
          isTermsButtonDisabled: false
        });
      })
      .catch(function(res) {
        console.log(res);
      });
  };

  render() {
    const { selectedDay } = this.state;
    const closeBtn = (
      <button
        className="close"
        onClick={() => this.props.toggle("extendRental")}
      >
        &times;
      </button>
    );
    return (
      <div>
        <Modal
          className="feedback-modal"
          isOpen={this.props.modalOpen}
          toggle={() => this.props.toggle("extendRental")}
          size="lg"
        >
          <ModalHeader
            toggle={this.toggle}
            close={closeBtn}
            style={{ background: "#3E3F9B", paddingLeft: "50px" }}
          >
            Extend Rental
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="reportreason" sm={2} size="lg" hidden>
                  Email
                </Label>
              </FormGroup>

              <FormGroup row>
                <Label for="feedback-message" sm={2} size="lg" hidden>
                  feedback
                </Label>
                <Col style={{ textAlign: "center" }} sm={12}>
                  <DayPicker
                    name="viewItemStartTime"
                    onDayClick={this.handleStartDayChange}
                    selectedDays={this.state.selectedDay}
                    placeholder="Please select date."
                    disabledDays={{
                      after: this.props.after,
                      before: this.props.before
                    }}
                  />
                </Col>
                <Col sm="12" style={{ padding: 10 }}>
                  <div className="container post-info-container">
                    <div className="left-side">
                      <img
                        className="left-side-icon"
                        style={{ width: "100%" }}
                        src={require("../../../Common/images/icon_price.svg")}
                        alt="search"
                      />
                    </div>
                    <div className="right-side">
                      <h4 className="post-title">Total </h4>{" "}
                      {/* <Button type="button" onClick={this.calculateTotal}>
                        {" "}
                        Calculate Total
                      </Button> */}
                      {this.state.calculateTotalError ? (
                        <div style={{ color: "red", padding: "15px 0" }}>
                          Please select required fields and fix errors
                        </div>
                      ) : null}
                      {this.state.emptyAddressError ? (
                        <div style={{ color: "red", padding: "15px 0" }}>
                          Please enter an address
                        </div>
                      ) : null}
                      <div
                        className="post-title-username post-info-block"
                        style={{ padding: "10px 0 10px" }}
                      >
                        <div className="rates-container">
                          <p className="rateFreq">Rental Amount </p>
                          <p className="ratePrice">{this.state.rentalAmount}</p>
                        </div>

                        <div className="rates-container">
                          <p className="rateFreq">Delivery Charges </p>
                          <p className="ratePrice">
                            {this.state.rentalDeliveryAmount}
                          </p>
                        </div>

                        <div className="rates-container">
                          <p className="rateFreq">Taxes </p>
                          <p className="ratePrice">{this.state.rentalGst}</p>
                        </div>

                        <div className="rates-container total-amout-container">
                          <p
                            className="rateFreq"
                            style={{ fontWeight: 600, color: "#000" }}
                          >
                            Total Amount{" "}
                          </p>
                          <p
                            className="ratePrice"
                            style={{ color: "#8AC53F", fontWeight: 600 }}
                          >
                            {this.state.totalRentalAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </FormGroup>
              <StripeCheckout
                stripeKey={process.env.REACT_APP_STRIPE}
                token={this.handleToken}
                className="btn btn-success"
                name="Make Payment"
              >
                <Button
                  type="button"
                  disabled={this.state.totalRentalAmount <= 0 ? true : false}
                  block
                  className="post-page-continue postview-submitbutton"
                >
                  Make Payment
                </Button>
              </StripeCheckout>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ExtendRentalModal.propTypes = {};

export default ExtendRentalModal;
